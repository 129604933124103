import React, { useContext, useEffect, useState } from 'react';
import PrivateRoute from '../PrivateRoute';
import { UserContext } from '../UserContext';
import { Link } from 'gatsby';
import axios from 'axios';
import displayErrors from '../utils';
import { useAlert } from 'react-alert';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import EmailTemplate from '../emailTemplate';

const JoditEditor = React.lazy(() => {
  return import('jodit-react');
});

const EditPage = () => {
  const alert = useAlert();
  const { logout, getSession } = useContext(UserContext);
  const [problem, setProblem] = useState(null);
  const [title, setTitle] = useState('');
  const [question, setQuestion] = useState('');
  const [solution, setSolution] = useState('');
  const isSSR = typeof window === 'undefined';

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const saveData = async () => {
    const { headers } = await getSession();
    var problemId;
    if (window) {
      problemId = queryString.parse(window.location.search).id;
    }
    if (problemId) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/problems/${problemId}`,
          { title, question, solution },
          { headers }
        )
        .then((res) => {
          alert.show(<div style={{ textTransform: 'initial' }}>Saved!</div>, {
            type: 'success',
          });
        })
        .catch((err) => {
          displayErrors(err, alert);
        });
    } else {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/problems/`,
          { title, question, solution },
          { headers }
        )
        .then((res) => {
          alert.show(<div style={{ textTransform: 'initial' }}>Created!</div>, {
            type: 'success',
          });
          setProblem({ problemId: res.data.message.problemId });
          navigate(`/edit?id=${res.data.message.problemId}`);
        })
        .catch((err) => {
          displayErrors(err, alert);
        });
    }
  };

  useEffect(() => {
    const getProblemData = async () => {
      const { headers } = await getSession();
      var problemId;
      if (window) {
        problemId = queryString.parse(window.location.search).id;
      }
      if (problemId) {
        axios
          .get(`${process.env.GATSBY_API_URL}/problems/${problemId}`, {
            headers,
          })
          .then((res) => {
            setProblem(res.data.message);
            setTitle(res.data.message.title);
            setQuestion(res.data.message.question);
            setSolution(res.data.message.solution);
          })
          .catch((err) => {
            displayErrors(err, alert);
          });
      } else {
        setProblem({ problemId: 'N/A' });
      }
    };
    getProblemData();
  }, []);

  return (
    <PrivateRoute>
      <button type="button" onClick={() => navigate('/list')}>
        Back
      </button>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <button type="button" onClick={() => logout()}>
        Logout
      </button>
      <br />
      <br />
      {problem ? (
        <table width="100%">
          <tbody>
            <tr>
              <td width="45%">
                <p>
                  <b>ID: </b>
                  {problem.problemId}
                </p>
                <b>Title: </b>
                <input
                  type="title"
                  id="title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <br />
                <br />
                <b>Question: </b>
                <br />

                {!isSSR && (
                  <React.Suspense fallback={<div>Loading RTE</div>}>
                    <JoditEditor
                      value={question}
                      config={config}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={(e) => setQuestion(e.target.innerHTML)} // preferred to use only this option to update the content for performance reasons
                    />
                  </React.Suspense>
                )}

                <br />
                <br />
                <b>Solution: </b>
                <br />
                {!isSSR && (
                  <React.Suspense fallback={<div>Loading RTE</div>}>
                    <JoditEditor
                      value={solution}
                      config={config}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={(e) => setSolution(e.target.innerHTML)} // preferred to use only this option to update the content for performance reasons
                    />
                  </React.Suspense>
                )}
                <br />
                <br />
                <button type="button" onClick={() => saveData()}>
                  Save
                </button>
              </td>
              <td width="10%"></td>
              <td>
                <EmailTemplate
                  title={title}
                  question={question}
                  solution={solution}
                />
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        'Loading...'
      )}
    </PrivateRoute>
  );
};

export default EditPage;
